import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { getPageObject, mapEdgesToNodes } from '../lib/helpers'
import { Page } from '../lib/interfaces/Page'

export const Head = ({ location }: { location: any }) => {
  const pages: Page[] = mapEdgesToNodes(useStaticQuery(PagesConfigQuery).allSanityPage)
  const currentPage = getPageObject(pages, location.pathname)
  const baseUrl: string = useStaticQuery(PagesConfigQuery).site.siteMetadata.siteUrl
  const pageUrl = baseUrl + location.pathname

  if (!currentPage) {
    return null
  }

  const seoMetadata = currentPage.seoMetadata

  return (
    <>
      {/* Primary Meta Tags */}
      <title>{seoMetadata.title}</title>
      <meta name='title' content={seoMetadata.title} />
      <meta name='description' content={seoMetadata.description} />
      {seoMetadata.keywords && seoMetadata.keywords.length > 0 && (
        <meta name='keywords' content={seoMetadata.keywords.join(', ')} />
      )}

      {/* Open Graph / Facebook */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={pageUrl} />
      <meta property='og:title' content={seoMetadata.title} />
      <meta property='og:description' content={seoMetadata.description} />
      <meta
        property='og:image'
        content={seoMetadata.ogImage ? seoMetadata.ogImage.asset.url : ''}
      />

      {/* Twitter */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={pageUrl} />
      <meta property='twitter:title' content={seoMetadata.title} />
      <meta property='twitter:description' content={seoMetadata.description} />
      <meta
        property='twitter:image'
        content={seoMetadata.ogImage ? seoMetadata.ogImage.asset.url : ''}
      />
    </>
  )
}

const PagesConfigQuery = graphql`
  query PagesConfigQuery {
    allSanityPage {
      edges {
        node {
          id
          title
          url
          seoMetadata {
            title
            description
            keywords
            ogImage {
              alt
              asset {
                url
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
