import React from 'react'
import ContactForm from '../Contact/ContactForm'
import Section from '../Section/Section'
import SectionTitle from '../Section/SectionTitle'

const ContactSection = () => {
  return (
    <Section backgroundColor='white'>
      <div tw='text-center mb-8 md:mb-14 lg:mb-24'>
        <SectionTitle title='Feel that tingling in your fingertips?' color='gray' />
        <p tw='max-w-5xl mx-auto leading-loose text-xl'>
          ...then get in touch with us - we can't wait to hear from you!
        </p>
      </div>
      <div tw='py-8 lg:py-16 px-4 mx-auto max-w-screen-md'>
        <ContactForm />
      </div>
    </Section>
  )
}

export default ContactSection
