import React from 'react'
import Layout from '../components/Layout'
import ContactSection from '../components/Sections/ContactSection'

const ContactPage = () => {
  return (
    <Layout>
      <ContactSection />
    </Layout>
  )
}

export default ContactPage

export { Head } from './../components/Head'
