import tw from 'twin.macro'

const inputStyles = 'border border-gray-300 text-sm block w-full p-2.5 focus:ring-2 focus:ring-turquoiseP focus:ring-inset'

export const StyledLabel = tw.label`block mb-2 text-sm font-medium`

export const StyledInput = tw.input`${inputStyles}`

export const StyledTextarea = tw.textarea`${inputStyles}`

export const StyledErrorSpan = tw.span`text-xs text-red-500`

export const contactFormVariants = {
   hidden: {
      opacity: 0,
   },
   visible: {
      opacity: 1,
      transition: {
         type: 'spring',
         when: 'beforeChildren',
         staggerChildren: 0.1,
      },
   },
}

export const inputGroupVariants = {
   hidden: {
      opacity: 0,
      y: 20,
   },
   visible: {
      opacity: 1,
      y: 0,
   },
}
