import { useFormspark } from '@formspark/use-formspark'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  contactFormVariants,
  inputGroupVariants,
  StyledErrorSpan,
  StyledInput,
  StyledLabel,
  StyledTextarea,
} from './ContactFormStyles'

const FORMSPARK_FORM_ID = '2U2KwbuC'

interface Inputs {
  company: string
  firstName: string
  lastName: string
  email: string
  subjet: string
  message: string
  phoneNumber: string
}

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await submit({ ...data })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful])

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      tw='space-y-8 -my-8'
      initial='hidden'
      animate='visible'
      variants={contactFormVariants}
    >
      <motion.div variants={inputGroupVariants}>
        <StyledLabel htmlFor='company'>Company</StyledLabel>
        <StyledInput type='text' id='company' {...register('company')} />
      </motion.div>
      <div tw='flex flex-wrap -mx-2 space-y-8 md:space-y-0'>
        <motion.div variants={inputGroupVariants} tw='w-full md:w-1/2 px-2'>
          <StyledLabel htmlFor='firstName'>First Name</StyledLabel>
          <StyledInput type='text' id='firstName' {...register('firstName', { required: true })} />
          {errors.firstName && errors.firstName.type === 'required' && (
            <StyledErrorSpan>First Name is required</StyledErrorSpan>
          )}
        </motion.div>
        <motion.div variants={inputGroupVariants} tw='w-full md:w-1/2 px-2'>
          <StyledLabel htmlFor='lastName'>Name</StyledLabel>
          <StyledInput type='text' id='lastName' {...register('lastName', { required: true })} />
          {errors.lastName && errors.lastName.type === 'required' && (
            <StyledErrorSpan>Name is required</StyledErrorSpan>
          )}
        </motion.div>
      </div>
      <motion.div variants={inputGroupVariants}>
        <StyledLabel htmlFor='email'>Email</StyledLabel>
        <StyledInput type='email' id='email' {...register('email', { required: true })} />
        {errors.email && errors.email.type === 'required' && (
          <StyledErrorSpan>Email is required</StyledErrorSpan>
        )}
      </motion.div>
      <motion.div variants={inputGroupVariants}>
        <StyledLabel htmlFor='phone'>Phone</StyledLabel>
        <StyledInput type='tel' id='phone' {...register('phoneNumber', { required: true })} />
        {errors.email && errors.email.type === 'required' && (
          <StyledErrorSpan>Phone is required</StyledErrorSpan>
        )}
      </motion.div>
      <motion.div variants={inputGroupVariants}>
        <StyledLabel htmlFor='subject'>Subject</StyledLabel>
        <StyledInput type='text' id='subject' {...register('subjet', { required: true })} />
        {errors.subjet && errors.subjet.type === 'required' && (
          <StyledErrorSpan>Subject is required</StyledErrorSpan>
        )}
      </motion.div>
      <motion.div variants={inputGroupVariants}>
        <StyledLabel htmlFor='message'>Type in your message...</StyledLabel>
        <StyledTextarea id='message' rows={7} {...register('message', { required: true })} />
        {errors.message && errors.message.type === 'required' && (
          <StyledErrorSpan>Message is required</StyledErrorSpan>
        )}
      </motion.div>
      <motion.button
        variants={inputGroupVariants}
        type='submit'
        tw='flex items-center justify-center py-3 px-4 rounded-sm transition duration-500 ease-in-out uppercase hover:scale-105 bg-turquoiseP text-white enabled:hover:bg-turquoiseS'
      >
        {submitting && Object.keys(errors).length === 0 && (
          <svg
            tw='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              tw='opacity-50'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              stroke-width='4'
            ></circle>
            <path
              tw='opacity-75'
              fill='#fdd460'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        )}
        <span>Send message</span>
      </motion.button>
    </motion.form>
  )
}

export default ContactForm
